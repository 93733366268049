export const ACCOUNT = {
    LOGIN: "/Account/login",
    FORGOT: "/Account/ForgotPassword",
    REFRESH_TOKEN: "/Account/Refresh-token",
    FETCH_PROFILE: "/Account/Profile/Update",
    UPDATE_PROFILE: "/Account/Profile/Update",
    FETCH_CLAIMS: "/Regulator/Claims",
    CHANGE_PASSWORD: "/Account/ChangePassword",
    LOGOUT: "/Account/Logout",
    VERIFY_EMAIL: "Account/Verify",
};

export const COMPANY = {
    SIGNUP: "/Company/Signup",
    RESEND_ACTIVATION: "/Company/SendConfirmation",
    FETCH_POSITIONS: "/Company/Positions",
    FETCH_PROFILE: "/Company/Update",
    UPDATE_PROFILE: "/Company/Profile/Update",
};

export const USER = {
    FETCH_PROFILE: "",
};

export const USERS = {
    LIST: "/User/List",
    ACTIVE_USERS: "User/Active/List",
    CREATE: "User/Create",
    FETCH_SINGLE_USER: (userId: string) => `/User/${userId}/Update`,
    UPDATE_SINGLE_USER: (userId: string) => `/User/${userId}/Update`,
    UPDATE_SINGLE_USER_ROLES: (userId: string) => `/User/${userId}/Create`,
    FETCH_CREATORS: "Report/Settings/CreateUsers",
    FETCH_REVIEWERS: "Report/Settings/ReviewUsers",
    // this is the actual endpoint to fetch creators and reviewers
    LEVELS: "Report/Settings/Level",
    SEND_CREDENTIALS: (id: string) => `/User/${id}/sendcredentials`,
    DELETE_USER: (userId: string) => `/User/${userId}/Delete`,
};

export const REPORT = {
    CREATE: "/Report/Create",
    FETCH_REPORTS: "/Report/List",
    FETCH_REPORT: (id: string) => `/Report/${id}/details`,
    FETCH_REPORT_HISTORY: (id: string) => `/Report/${id}/History`,
    FETCH_REPORT_DETAILS: (id: string) => `/Report/${id}/update`,
    DELETE_REPORT: (id: string) => `/Report/${id}/Delete`,
    PREVIEW_REPORT: (id: string) => `/Report/${id}/Preview`,
    DETAILS: (id: string) => `/Report/${id}/details/update`,
    UPDATE_DOCUMENTS: (id: string) => `/Report/${id}/Documents/Update`,
    PROFILE: (id: string) => `/Report/${id}/profile/update`,
    PRINCIPLES: (reportId: string, principleName: string) =>
        `/Report/${reportId}/Principle/${principleName}/Update`,
    SIGNATORIES: (id: string) => `/Report/${id}/Signatories/update`,
    STRUCTURES: (id: string) => `/Report/${id}/Structure/Update`,
    SUBMIT: (id: string) => `/Report/${id}/Submit`,
    FLAG_PRINCIPLE: (id: string) => `/Report/${id}/Principle/Flag`,
    FLAG_PRINCIPLE_INPUT: (id: string) => `/Report/${id}/PrincipleInput/Flag`,
    COMMENT_PRINCIPLE: (id: string) => `/Report/${id}/PrincipleInput/Comment`,
    FLAG_STANDARD: (id: string) => `/Report/${id}/Standard/Flag`,
    FLAG_SIGNATORY: (id: string) => `/Report/${id}/Signatory/Flag`,
    FLAG_DOCUMENT: (id: string) => `/Report/${id}/Document/Flag`,
    FLAG_POSITION: (id: string) => `/Report/${id}/Position/Flag`,
    APPROVE: (id: string) => `/Report/${id}/Level/Approve`,
    REVIEW: (id: string) => `/Report/${id}/Review`,
    SIGN: (id: string) => `/Report/${id}/SignReport`,
    SEND_TO_REGULATOR: (id: string) => `/Report/${id}/SendToRegulator`,
    MAKE_REVIEWER: "/Report/Settings/ReviewUsers",
    MAKE_CREATOR: "/Report/Settings/CreateUsers",
    MAKE_SIGNATORY: "/Report/Settings/SignatoryUsers",
    CREATE_AUDITOR: "/Auditor/Create",
    CREATE_COMMITEE: "/Commitee/Create/Modified",
    FETCH_COMMITTEES: "/Commitee/List",
    FETCH_AUDITORS: "/Auditor/List",
    CREATE_CONSULTANT: "/Consultant/Create",
    FETCH_CONSULTANTS: "/Consultant/List",
};

export const FILE = {
    UPLOAD: "/api/File/Upload",
    GET: (filename: string): string => `/api/File/Get/${filename}`,
    DELETE: (filename: string): string => `/api/File/Delete/${filename}`,
};
