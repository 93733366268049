import http from "@/utils/http";
import resolveRequestError from "@/utils/errorHandler";
import { REPORT } from "./endpoints";

export default class ReportService {
    static async fetchReportList(): Promise<any> {
        try {
            const data = await http.get(REPORT.FETCH_REPORTS);
            return data;
        } catch (error) {
            return resolveRequestError(error);
        }
    }

    static async fetchReportById(id: string): Promise<any> {
        try {
            const data = await http.get(REPORT.FETCH_REPORT(id));
            return data;
        } catch (error) {
            return resolveRequestError(error);
        }
    }

    static async fetchReportHistory(id: string): Promise<any> {
        try {
            const data = await http.get(REPORT.FETCH_REPORT_HISTORY(id));
            return data;
        } catch (error) {
            return resolveRequestError(error);
        }
    }

    static async fetchReportDetails(id: string): Promise<any> {
        try {
            const data = await http.get(REPORT.FETCH_REPORT_DETAILS(id));
            return data;
        } catch (error) {
            return resolveRequestError(error);
        }
    }

    static async updateReportDetails({
        id,
        body,
    }: {
        id: string;
        body: any;
    }): Promise<any> {
        try {
            const data = await http.post(REPORT.DETAILS(id), body);
            return data;
        } catch (error) {
            return resolveRequestError(error);
        }
    }

    static async approveOrDeclineReport({
        id,
        body,
    }: {
        id: string;
        body: any;
    }): Promise<any> {
        try {
            const data = await http.post(REPORT.APPROVE(id), body);
            return data;
        } catch (error) {
            return resolveRequestError(error);
        }
    }

    /**
     * Update Report Documents
     * @param id
     * @param body
     */
    static async updateReportDocuments({
        id,
        body,
    }: {
        id: string;
        body: any;
    }): Promise<any> {
        try {
            const data = await http.post(REPORT.UPDATE_DOCUMENTS(id), body);
            return data;
        } catch (error) {
            return resolveRequestError(error);
        }
    }

    static async createReport({
        templateId,
        regulatorId,
    }: {
        templateId: string;
        regulatorId: any;
    }): Promise<any> {
        try {
            const data = await http.post(REPORT.CREATE, {
                templateId,
                regulatorId,
            });
            return data;
        } catch (error) {
            return resolveRequestError(error);
        }
    }

    static async deleteReport(id: string): Promise<any> {
        try {
            const data = await http.post(REPORT.DELETE_REPORT(id));
            return data;
        } catch (error) {
            return resolveRequestError(error);
        }
    }

    static async fetchPreview(id: string): Promise<any> {
        try {
            const data = await http.get(REPORT.PREVIEW_REPORT(id));
            return data;
        } catch (error) {
            return resolveRequestError(error);
        }
    }

    static async createAuditor(body: any): Promise<any> {
        try {
            const data = await http.post(REPORT.CREATE_AUDITOR, body);
            return data;
        } catch (error) {
            return resolveRequestError(error);
        }
    }

    /**
     * Make User A Reviewer
     * @param body
     */
    static async makeReviewer(body: Array<string>): Promise<any> {
        try {
            const data = await http.post(REPORT.MAKE_REVIEWER, body);
            return data;
        } catch (error) {
            return resolveRequestError(error);
        }
    }

    /**
     * Make User A Creator
     * @param body
     */
    static async makeCreator(body: Array<string>): Promise<any> {
        try {
            const data = await http.post(REPORT.MAKE_CREATOR, body);
            return data;
        } catch (error) {
            return resolveRequestError(error);
        }
    }

    /**
     * Make User A Signatory
     * @param body
     */
    static async makeSignatory(body: Array<string>): Promise<any> {
        try {
            const data = await http.post(REPORT.MAKE_SIGNATORY, body);
            return data;
        } catch (error) {
            return resolveRequestError(error);
        }
    }

    static async fetchAuditors(): Promise<any> {
        try {
            const data = await http.get(REPORT.FETCH_AUDITORS);
            return data;
        } catch (error) {
            return resolveRequestError(error);
        }
    }

    static async createConsultant(body: any): Promise<any> {
        try {
            const data = await http.post(REPORT.CREATE_CONSULTANT, body);
            return data;
        } catch (error) {
            return resolveRequestError(error);
        }
    }

    static async createCommittee(body: any): Promise<any> {
        try {
            const data = await http.post(REPORT.CREATE_COMMITEE, body);
            return data;
        } catch (error) {
            return resolveRequestError(error);
        }
    }

    static async fetchCommittees(): Promise<any> {
        try {
            const data = await http.get(REPORT.FETCH_COMMITTEES);
            return data;
        } catch (error) {
            return resolveRequestError(error);
        }
    }

    static async fetchConsultants(): Promise<any> {
        try {
            const data = await http.get(REPORT.FETCH_CONSULTANTS);
            return data;
        } catch (error) {
            return resolveRequestError(error);
        }
    }

    static async updateStakeholderDetails({
        id,
        body,
    }: {
        id: string;
        body: any;
    }): Promise<any> {
        try {
            const data = await http.post(REPORT.PROFILE(id), body);
            return data;
        } catch (error) {
            return resolveRequestError(error);
        }
    }

    static async updatePrinciple({
        reportId,
        principleName,
        body,
    }: {
        reportId: string;
        principleName: string;
        body: any;
    }): Promise<any> {
        try {
            const data = await http.post(
                REPORT.PRINCIPLES(reportId, principleName),
                body,
            );
            return data;
        } catch (error) {
            return resolveRequestError(error);
        }
    }

    static async updateSignatories({
        id,
        body,
    }: {
        id: string;
        body: any;
    }): Promise<any> {
        try {
            const data = await http.post(REPORT.SIGNATORIES(id), body);
            return data;
        } catch (error) {
            return resolveRequestError(error);
        }
    }

    static async updateStructure({
        id,
        body,
    }: {
        id: string;
        body: any;
    }): Promise<any> {
        try {
            const data = await http.post(REPORT.STRUCTURES(id), body);
            return data;
        } catch (error) {
            return resolveRequestError(error);
        }
    }

    static async submitReport(id: string): Promise<any> {
        try {
            const data = await http.post(REPORT.SUBMIT(id));
            return data;
        } catch (error) {
            return resolveRequestError(error);
        }
    }

    static async reviewReport({
        id,
        body,
    }: {
        id: string;
        body: any;
    }): Promise<any> {
        try {
            const data = await http.post(REPORT.REVIEW(id), body);
            return data;
        } catch (error) {
            return resolveRequestError(error);
        }
    }

    static async signReport({
        id,
        body,
    }: {
        id: string;
        body: any;
    }): Promise<any> {
        try {
            const data = await http.post(REPORT.SIGN(id), body);
            return data;
        } catch (error) {
            return resolveRequestError(error);
        }
    }

    static async sendToRegulator(id: string): Promise<any> {
        try {
            const data = await http.post(REPORT.SEND_TO_REGULATOR(id));
            return data;
        } catch (error) {
            return resolveRequestError(error);
        }
    }

    static async flagStandard({
        id,
        body,
    }: {
        id: string;
        body: any;
    }): Promise<any> {
        try {
            const data = await http.post(REPORT.FLAG_STANDARD(id), body);
            return data;
        } catch (error) {
            return resolveRequestError(error);
        }
    }

    static async flagDocument({
        id,
        body,
    }: {
        id: string;
        body: any;
    }): Promise<any> {
        try {
            const data = await http.post(REPORT.FLAG_DOCUMENT(id), body);
            return data;
        } catch (error) {
            return resolveRequestError(error);
        }
    }

    static async flagSignatory({
        id,
        body,
    }: {
        id: string;
        body: any;
    }): Promise<any> {
        try {
            const data = await http.post(REPORT.FLAG_SIGNATORY(id), body);
            return data;
        } catch (error) {
            return resolveRequestError(error);
        }
    }

    static async flagPosition({
        id,
        body,
    }: {
        id: string;
        body: any;
    }): Promise<any> {
        try {
            const data = await http.post(REPORT.FLAG_POSITION(id), body);
            return data;
        } catch (error) {
            return resolveRequestError(error);
        }
    }

    static async flagPrincipleInput({
        id,
        body,
    }: {
        id: string;
        body: any;
    }): Promise<any> {
        try {
            const data = await http.post(REPORT.FLAG_PRINCIPLE_INPUT(id), body);
            return data;
        } catch (error) {
            return resolveRequestError(error);
        }
    }

    static async flagPrinciple({
        id,
        body,
    }: {
        id: string;
        body: any;
    }): Promise<any> {
        try {
            const data = await http.post(REPORT.FLAG_PRINCIPLE(id), body);
            return data;
        } catch (error) {
            return resolveRequestError(error);
        }
    }

    static async commentPrinciple({
        id,
        body,
    }: {
        id: string;
        body: any;
    }): Promise<any> {
        try {
            const data = await http.post(REPORT.COMMENT_PRINCIPLE(id), body);
            return data;
        } catch (error) {
            return resolveRequestError(error);
        }
    }
}
